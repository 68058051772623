var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-1" },
    [
      _c(
        "b-overlay",
        {
          attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center mt-5" },
                    [
                      _c("base-icon", {
                        attrs: { name: "loading", width: "35", height: "35" },
                      }),
                      _vm._v(" "),
                      _c("p", { attrs: { id: "cancel-label" } }, [
                        _vm._v("Operazione in corso..."),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          !_vm.isLoading
            ? _c("div", [
                _vm.ok
                  ? _c(
                      "div",
                      [
                        _c("validation-observer", {
                          ref: "observer",
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ invalid, handleSubmit }) {
                                  return [
                                    _c(
                                      "b-form",
                                      {
                                        on: {
                                          submit: function ($event) {
                                            $event.preventDefault()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card",
                                          {
                                            attrs: {
                                              header: "Dati Identificativi",
                                              "header-tag": "header",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-text",
                                              [
                                                _c("b-row", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-datepicker", {
                                                        attrs: {
                                                          vid: "book_date",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].book_date.label,
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .book_date.label,
                                                          rules:
                                                            _vm.getRules(
                                                              "book_date"
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .book_date,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "book_date",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].book_date",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-datepicker", {
                                                        attrs: {
                                                          vid: "effective_date",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].effective_date
                                                            .label,
                                                          label: "Data Valuta",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .effective_date,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "effective_date",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].effective_date",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          vid: "invoice_protocol_number",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ]
                                                            .invoice_protocol_number
                                                            .label,
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .invoice_protocol_number
                                                              .label,
                                                          placeholder:
                                                            "Inserisci un numero di protocollo",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .invoice_protocol_number,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "invoice_protocol_number",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].invoice_protocol_number",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("b-row", [
                                                  _vm.form[_vm.rep]
                                                    .agencybook_type !== 2
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-md-4",
                                                        },
                                                        [
                                                          _c("base-select", {
                                                            attrs: {
                                                              name: _vm.beForm[
                                                                _vm.rep
                                                              ]
                                                                .payment_reference_id
                                                                .label,
                                                              vid: "payment_reference_id",
                                                              label:
                                                                _vm.beForm[
                                                                  _vm.rep
                                                                ]
                                                                  .payment_reference_id
                                                                  .label,
                                                              options:
                                                                _vm.opt_payment_reference,
                                                              rules:
                                                                _vm.getRules(
                                                                  "payment_reference_id"
                                                                ),
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form[
                                                                  _vm.rep
                                                                ]
                                                                  .payment_reference_id,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.form[
                                                                      _vm.rep
                                                                    ],
                                                                    "payment_reference_id",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "form[rep].payment_reference_id",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.form[_vm.rep]
                                                    .agencybook_type == 2
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-md-3",
                                                        },
                                                        [
                                                          _c("base-select", {
                                                            attrs: {
                                                              name: _vm.beForm[
                                                                _vm.rep
                                                              ].sector_id.label,
                                                              vid: "balance",
                                                              label: "Saldo",
                                                              options:
                                                                _vm.opt_sectors,
                                                              rules:
                                                                _vm.getRules(
                                                                  "sector_id"
                                                                ),
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form
                                                                  .balance,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.form,
                                                                    "balance",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "form.balance",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  (_vm.form[_vm.rep]
                                                    .agencybook_type == 0 ||
                                                    _vm.form[_vm.rep]
                                                      .agencybook_type == 3) &&
                                                  !_vm.disable_receipt
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-md-3",
                                                        },
                                                        [
                                                          _c("base-select", {
                                                            attrs: {
                                                              name: _vm.beForm[
                                                                _vm.rep
                                                              ].sector_id.label,
                                                              vid: "receipt",
                                                              label: "Entrate",
                                                              options:
                                                                _vm.opt_sectors,
                                                              rules:
                                                                _vm.getRules(
                                                                  "sector_id"
                                                                ),
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form
                                                                  .receipt,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.form,
                                                                    "receipt",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "form.receipt",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  (_vm.form[_vm.rep]
                                                    .agencybook_type == 1 ||
                                                    _vm.form[_vm.rep]
                                                      .agencybook_type == 3) &&
                                                  !_vm.disable_expenses
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-md-3",
                                                        },
                                                        [
                                                          _c("base-select", {
                                                            attrs: {
                                                              name: _vm.beForm[
                                                                _vm.rep
                                                              ].sector_id.label,
                                                              vid: "expenses",
                                                              label: "Uscite",
                                                              options:
                                                                _vm.opt_sectors,
                                                              rules:
                                                                _vm.getRules(
                                                                  "sector_id"
                                                                ),
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form
                                                                  .expenses,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.form,
                                                                    "expenses",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "form.expenses",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "b-row",
                                                  [
                                                    _vm.form[_vm.rep]
                                                      .agencybook_type == 0 ||
                                                    _vm.form[_vm.rep]
                                                      .agencybook_type == 1
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-group col-md-4",
                                                          },
                                                          [
                                                            _c("base-select", {
                                                              attrs: {
                                                                name: "salesmen",
                                                                vid: "salesmen",
                                                                label:
                                                                  "Produttori",
                                                                options:
                                                                  _vm.opt_salesmen,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.form
                                                                    .salesman_id,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.form,
                                                                      "salesman_id",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "form.salesman_id",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _vm.form[_vm.rep]
                                                      .agencybook_type == 0 ||
                                                    _vm.form[_vm.rep]
                                                      .agencybook_type == 1
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-group col-md-3",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              [
                                                                _c(
                                                                  "b-button-toolbar",
                                                                  [
                                                                    _c(
                                                                      "b-button-group",
                                                                      [
                                                                        _c(
                                                                          "base-input",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                name: "Anagrafica Contatti",
                                                                                vid: "agency_contact_id",
                                                                                label:
                                                                                  "Anagrafica Contatti",
                                                                                readonly: true,
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .form
                                                                                    .inpt_label,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm.form,
                                                                                      "inpt_label",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "form.inpt_label",
                                                                              },
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "b-button",
                                                                          {
                                                                            staticClass:
                                                                              "mt-4 btn-quick",
                                                                            attrs:
                                                                              {
                                                                                size: "sm",
                                                                                text: "Button",
                                                                                variant:
                                                                                  "lisaweb",
                                                                                title:
                                                                                  "Ricerca Veloce",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                _vm.openQuickSearchAgencyContacts,
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "b-icon",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    icon: "search",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _vm.form[_vm.rep]
                                                      .agencybook_type == 0 ||
                                                    _vm.form[_vm.rep]
                                                      .agencybook_type == 1
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-group col-md-3",
                                                          },
                                                          [
                                                            _vm.agency_contacts_data
                                                              ? _c("span", {
                                                                  staticClass:
                                                                    "info",
                                                                  domProps: {
                                                                    innerHTML:
                                                                      _vm._s(
                                                                        _vm.toInfoData(
                                                                          _vm.agency_contacts_data,
                                                                          "agency_contacts"
                                                                        )
                                                                      ),
                                                                  },
                                                                })
                                                              : _vm._e(),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _c(
                                                      "quick-search-agency-contacts-modal",
                                                      {
                                                        ref: "quickSearchAgencyContacts",
                                                        on: {
                                                          input:
                                                            _vm.handleAgencyContactsModalInput,
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("b-row", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          vid: "additional_description",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ]
                                                            .additional_description
                                                            .label,
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .additional_description
                                                              .label,
                                                          placeholder:
                                                            "Inserisci una descrizione",
                                                          rules: _vm.getRules(
                                                            "additional_description"
                                                          ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .additional_description,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "additional_description",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].additional_description",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-currency", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].gross.label,
                                                          vid: "gross",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .gross.label,
                                                          options: {
                                                            currency: "EUR",
                                                            locale: "it-IT",
                                                            precision: 2,
                                                          },
                                                          rules:
                                                            _vm.getRules(
                                                              "gross"
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .gross,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "gross",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].gross",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "py-2 mb-4" },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  type: "button",
                                                  disabled: invalid,
                                                  variant: "lisaweb",
                                                  size: "sm",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return handleSubmit(
                                                      _vm.onSubmit
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                Salva\n              "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "b-button",
                                              {
                                                staticClass: "float-right",
                                                attrs: {
                                                  variant: "lisaweb",
                                                  size: "sm",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.$router.back()
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-icon-chevron-double-left",
                                                  {
                                                    attrs: {
                                                      "font-scale": "0.9",
                                                    },
                                                  }
                                                ),
                                                _vm._v("Torna indietro"),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            771280030
                          ),
                        }),
                      ],
                      1
                    )
                  : _c("div", [
                      _vm._v(
                        "\n        " + _vm._s(_vm.errorMessage) + "\n      "
                      ),
                    ]),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }